import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import HomeHero from '../components/HomeHero'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`Shenanigans`, `Trina Vargo`, `US-Ireland`, `US-IA`, `USIA`]}
    />
    <HomeHero />
  </Layout>
)

export default IndexPage
