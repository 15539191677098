import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import BookImage from '../BookImage'

import { ORDER_URL, DONATE_URL } from '../../lib/constants'

export default function HomeHero() {
  return (
    <Section>
      <Container>
        <ImageWrapper>
          <BookImage />
        </ImageWrapper>
        <ContentWrapper>
          <Title>The US-Ireland Relationship in Uncertain Times</Title>
          <Description>
            <i>Shenanigans</i>
            {` provides a rare insight into the US-Ireland relationship from a woman who has been at the center of it for more than two decades. As Senator Ted Kennedy’s foreign policy adviser and founder of the US-Ireland Alliance, as well as the George J. Mitchell Scholarship program and the Oscar Wilde Awards, Trina Vargo is uniquely positioned to know what the relationship needs to evolve and to survive. Vargo became part of the Clintons’ “enemies list” as a result of her role in Barack Obama’s presidential campaign in 2008.`}
          </Description>
          <Description>
            {`She has been attacked by a vocal but determined few as a result of an accurate and prescient opinion piece on immigration, and she even had to battle the Irish Film Board in her efforts that resulted in J.J. Abrams filming Star Wars: The Force Awakens in Ireland. Trina Vargo speaks truth to power. Her unwavering commitment, energy, and passion, combined with clear, evidentiary, and dispassionate analysis, cause her to question if there is a future for a historic relationship.`}
          </Description>
          <Description>
            <LinkToFile href="/docs/shenanigans-introduction.pdf">
              read the introduction
            </LinkToFile>
          </Description>
          <ButtonGroup>
            <ButtonWrapper>
              <Button
                color="#008b27"
                href={ORDER_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                order now
              </Button>
              <Caption>Available now on Amazon</Caption>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                color="#005819"
                href={DONATE_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                donate
              </Button>
              <Caption>to the US-Ireland Alliance</Caption>
            </ButtonWrapper>
          </ButtonGroup>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

const Section = styled.section`
  background-color: #f1f6ef;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 100px 20px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`

const ImageWrapper = styled.div`
  @media (min-width: 768px) {
    width: 50%;
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 480px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

const Title = styled.h1`
  color: #007e22;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: 0;
`

const Description = styled.p`
  color: #000;
  font-family: 'Cardo';
  &:last-child {
    margin-bottom: 36px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  @media (max-width: 767px) {
    &:first-child {
      margin-bottom: 20px;
    }
  }
  @media (min-width: 768px) {
    &:first-child {
      margin-right: 30px;
    }
  }
`

const Button = styled.a`
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-family: 'Nunito Sans', 'sans-serif';
  height: 40px;
  justify-content: center;
  margin-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms ease;
  width: 200px;
  ${({ color }) => css`
    background-color: ${color};
    &:hover {
      background-color: ${darken(0.025, color)};
    }
  `}
`

const Caption = styled.span`
  color: #007e22;
  font-family: 'Cardo';
  font-size: 14px;
  font-style: italic;
`

const LinkToFile = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: #005819;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`
